import md5 from 'js-md5'

const StringUtil = {

    _md5(data, len){
        const md5Data = md5(data)
        return md5Data.substring(0, len)
    },
    isUrl(str){
        const reg = /^((https|http)?:\/\/)[^\s]+/
        return reg.test(str)
    },
    extractUrl(str){
        const reg = /(https?:\/\/[^\s]+)/g
        const r = str.match(reg)
        if(!r){
            return ''
        }
        return r[0]
    },
    randomString(length) {
        let result = '';
        let characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
        let charactersLength = characters.length;

        for (let i = 0; i < length; i++) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
        }

        return result;
    },
    formatFilename(text) {
    let pattern = /[\\/:*?"<>|\r\n]+/g;
    return text.replace(pattern, this.randomString(4));
    }
}

export default StringUtil