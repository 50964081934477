import StringUtil from "./String";
import service from "./http";
import store from '../store'
import axios from "axios";

const webview = {
    login(card){
        return service.post('api/card/login', {
            card: card,
            version: store.state.apiVersion
        })
    },
    info(){
        return service.post('api/app/info',{
            version: store.state.apiVersion
        })
    },
    parse(card, url){
        return service.post('api/parse/execute', {
            card: card,
            url : url,
            pid : StringUtil._md5(url + card, 16),
            version: store.state.apiVersion
        })
    },
    writeConfig(key, value){
        return localStorage.setItem(key, value)
    },
    readConfig(key){
        return localStorage.getItem(key)
    },
    getIdmPath(){
        window.messageBox.error('该API在WEB版本中未被支持')
    },
    select_file(){
        return window.pywebview.api.select_file()
    },
    select_folder(){
        return window.pywebview.api.select_folder()
    },
    downloadByAria(id, url, name, save_path, rpc_url, rpc_token, referer){
        return axios
            .post(rpc_url, {
                jsonrpc: "2.0",
                method: "aria2.addUri",
                id: id,
                params: [
                    "token:" + rpc_token,
                    [url],
                    {
                        out: name,
                        dir: save_path,
                        referer: referer,
                        header: [
                            "user-Agent:Mozilla/5.0 (Windows NT 10.0; Win64; x64) AppleWebKit/537.36 (KHTML, like Gecko) Chrome/102.0.0.0 Safari/537.36",
                        ],
                    },
                ],
            })
    },
    downloadByThunder(name, url, save_path, referer){
        thunderLink.newTask({
            downloadDir: save_path, // 指定当前任务的下载目录名称，迅雷会在用户剩余空间最大的磁盘根目录中创建这个目录。【若不填此项，会下载到用户默认下载目录】
            tasks: [{
                name: name, // 指定下载文件名（含扩展名）。【若不填此项，将根据下载 URL 自动获取文件名】
                url: url // 指定下载地址【必填项】
            }],
            referer: referer, // 指定迅雷发起下载请求时上报的引用页【一般不必填写，除非某些下载地址的服务器会判断引用页】
        });
        //return window.pywebview.api.downloadByThunder(url, name, save_path, referer)
    },
    downloadByIDM(url, file_name, save_path, referer, silent){
        window.messageBox.error('该API在WEB版本中未被支持')
        //return window.pywebview.api.downloadByIDM(url, file_name, save_path, referer, silent)
    },
    downloadByIDMCom(url, file_name, save_path, referer, silent){
        window.messageBox.error('该API在WEB版本中未被支持')
        //return window.pywebview.api.downloadByIDMCom(url, file_name, save_path, referer, silent)
    }
}

export default webview