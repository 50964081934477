import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    redirect: 'main',
    children: [
      {
        path: '/main',
        name: 'main',
        component: () => import('@/views/main/')
      },
      {
        path: '/setting',
        name: 'setting',
        component: () => import('@/views/setting/')
      },
      {
        path: '/about',
        name: 'about',
        component: () => import('@/views/about/')
      }
      ,
      {
        path: '/batch',
        name: 'batch',
        component: () => import('@/views/batch/')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})

export default router
