<template>
  <div style="height: 100%; width: 100%">
    <el-container style="height: 100%; width: 100%">
      <div>
        <div class="logo-wrap">
          <div style=" display: flex; flex-direction: row; font-size: 1px;width: 40px;height: 40px;border-radius: 10px; background-color: dodgerblue; color: white; align-items: center;justify-content: center">
            IKun
          </div>
          <!--          <img width="40" src="@/assets/logo.png" alt="logo.png" />-->
        </div>
        <el-aside style="height: calc(100% - 60px);;width: 65px">
          <el-menu @select="selectItem" background-color="#0c193d" style="height: 100%;" default-active="chat" class="el-menu-vertical-demo"  :collapse="true">
            <el-menu-item index="main">
              <i style="color: white" class="el-icon-s-home"></i>
              <span slot="title">首页</span>
            </el-menu-item>
            <el-menu-item index="batch">
              <i style="color: white" class="el-icon-link"></i>
              <span slot="title">批量</span>
            </el-menu-item>
            <el-menu-item index="setting">
              <i style="color: white" class="el-icon-setting"></i>
              <span slot="title">设置</span>
            </el-menu-item>
            <el-menu-item index="about">
              <i style="color: white" class="el-icon-info"></i>
              <span slot="title">关于</span>
            </el-menu-item>
          </el-menu>
        </el-aside>
      </div>

      <el-container>
        <el-main>
          <keep-alive>
            <router-view />
          </keep-alive>
        </el-main>
      </el-container>
    </el-container>
  </div>
</template>
<script>
export default{
  data() {
    return {

    }
  },
  mounted() {
    const that = this
    const loading = this.$loading({
      lock: true,
      text: 'Loading',
      spinner: 'el-icon-loading',
      background: 'rgba(0, 0, 0, 0.7)'
    });
    setTimeout(function (){
      that.$webview.info().then(r => {
        console.log(r)
        if(r.code == 200){
          if(r.data._update){
            that.$alert(decodeURIComponent(r.data.update_msg), '有新版本', {
              confirmButtonText: '确定',
              callback: action => {

              }
            });
          }else{
            that.$message.success(`欢迎使用:${decodeURIComponent(r.data.notice)}`)
          }
          loading.close()
        }else{
          if(r.msg){
            that.$message.error(r.msg)
          }else{
            that.$message.error(r.error)
          }

        }
      })
    }, 3000)
  },
  methods:{
    selectItem(e){
      this.$router.push(e)
    }
  }
}
</script>
<style scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 400px;
}
.logo-wrap {
  background-color: #0c193d;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 64px;
  height: 50px;
  padding-top: 10px;
}


</style>
